@use '../base/variables.scss' as *;

.experts-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.expert-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 28px 34px;
  border: 1px solid rgba(56,96,165,0.14);
  box-shadow: 0 4px 8px 0 rgba(56,96,165,0.15);
  width: 290px;
  margin: 10px;
  display: flex;
  flex-direction: column;

  &__img {
    width: 120px;
    height: 120px;
    background-color: #eee; // temp
    border-radius: 50%;
    margin: 0 auto 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  
  &__info {
    color: #10203E;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;

    h4 {
      font-family: $ff-primary;
      font-weight: 600;
      font-size: 21px;
      line-height: 25px;
      margin-bottom: auto;
    }

    &_desc {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      flex: 1;
      margin-top: 10px;
    }

    .btn-wrapper {
      line-height: normal;
    }

    a {

      &.read-more {
        font-size: 13px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: -0.18px;
        display: inline-flex;
        align-items: center;
        color: #3EA447;
        position: relative;

        &::after {
          position: absolute;
          top: 100%;
          left: 0;
          width: 0%;
          height: 1px;
          background-color: #3EA447;
          content: '';
          transition: width .3s ease;
        } 

        svg {
          display: block;
          font-size: 18px;
        }

        &:hover {

          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    a.button {
      font-size: 17px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: -0.24px;
      color: #fff;
      background-color: #3EA447;
      border-radius: 80px;
      padding: 9px 16px;
      min-width: 158px;
      transition: background-color .3s ease;
      margin-top: 24px;
      display: inline-flex;
      justify-content: center;

      &:hover {
        background-color: #65B66C;
      }
    }
  }
}

.expert-form-control {
  width: 100%;
  margin-bottom: 20px !important;

  .MuiInput-formControl {
    margin-top: 0;
  }

  .MuiSelect-select.MuiSelect-select {
    border-radius: 4px;
    border: 1px solid #3EA447;
    background-color: #fff;
    padding-right: 34px;
    padding-left: 10px;
    display: flex;
    align-items: center;

    &:focus {
      background-color: #fff;
    }

    .expert-img {
      width: 30px;
      height: 30px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 14px;
      border-radius: 50%;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      color: #10203E;
    }
  }

  .MuiInput-underline:before, 
  .MuiInput-underline:after {
    display: none;
  }

  .MuiSelect-icon {
    right: 10px;
    color: #3EA447;
  }

}

.MuiMenuItem-root.expert-menu-item {
  display: flex;
  align-items: center;

  .expert-img {
    width: 30px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 14px;
    border-radius: 50%;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: #10203E;
  }
}

.back-wrapper {
  position: absolute;
  left: 0;
  top: -30px;
}