.nav__menu--2 {

  @media screen and (max-width: 540px) {
    a {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 480px) {

    .nav-links-wrap {

      a {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  .nav-links-left {
    margin-right: auto;
    display: flex;
    align-items: center;

    &__divider {
      display: none;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
      margin-right: 0;
      width: 100%;
      position: relative;

      a {
        padding-top: 8px;
        padding-bottom: 8px;

        &:nth-child(1) {
          position: absolute;
          left: 0;
          top: 0;
        }
        &:nth-child(4) {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    @media screen and (max-width: 480px) { 
      justify-content: center;
      flex-wrap: wrap;

      a {
        order: 0 !important;
        padding-top: 4px;
        padding-bottom: 4px;

        &:nth-child(1), &:nth-child(4) {
          position: static;
        }
      }

      &__divider {
        display: block;
        width: 100%;
      }
    }
  }
}